import React from "react"
import { graphql } from "gatsby";
import PageNotFound from "../components/404/404";
import AboutBlog from "../components/HomePage/AboutBlog/AboutBlog";
import Blog from "../components/HomePage/Blog/Blog";
import Layout from "../components/layouts/layout";
const NotFoundPage = ({ data }) => {
  const posts = data?.allGhostPosts?.edges;
  const indexBlock = data?.featuredHomepage.edges[0]
  return (<Layout>
    <PageNotFound />
    <AboutBlog indexBlock={indexBlock} />
    <div id="all_blogs">
      <Blog posts={posts} />
    </div>
  </Layout>)

}

export default NotFoundPage

export const pageQuery = graphql`
  query GhostPostQuery404 {
    allGhostPosts: allGhostPost(
      sort: {fields: published_at, order: DESC}
      filter: {tags: {elemMatch: {name: {eq: "EXAM_BOOSTER"}}}}
    ) {
      edges {
        node {
          ...GhostPostFields
        }
      }
    }
    featuredHomepage: allGhostPage(filter: {slug: {eq: "exam_booster_home-page"}}) {
      edges {
        node {
          ...GhostPageFields
        }
      }
    }
    featuredPosts: allGhostPost(
      filter: {tags: {elemMatch: {name: {eq: "EXAM_BOOSTER"}}}, featured: {eq: true}}
    ) {
      edges {
        node {
          ...GhostPostFields
        }
      }
    }
    featuredPages: allGhostPage(filter: {tags: {elemMatch: {name: {eq: "EXAM_BOOSTER"}}}, featured: {eq: true}}) {
      edges {
        node {
          ...GhostPageFields
        }
      }
    }
  }
`;